.details {
  background: #fff;
  padding: 30px;
}

@media (min-width: 992px) {
  .details {
    padding-top: 0;
    margin-top: 30px;
    width: 325px;
    float: right;
    margin-left: 15px;
  }
}

.details dt {
  float: left;
  width: 150px;
  font-weight: bold;
  clear: left;
}

.details dd {
  margin-left: 160px;
}

label {
  display: inline-block;
  cursor: pointer;
}

.btn-list, .btn-list li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.btn-list {
  margin-left: -10px;
  margin-right: -10px;
}

.btn-list li {
  padding: 10px;
  flex-grow: 1;
  flex-direction: column;
}

.btn-list a {
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  border: 1px solid #8d8d8d;
  padding: 5px 15px;
  color: inherit;
  text-decoration: none;
  flex-grow: 1;
  border-radius: 3px;
  text-align: center;
}

.btn-list a,
.btn-list a * {
  transition: .35s;
}

.btn-list .name {
  display: block;
  max-width: 300px;
  text-transform: uppercase;
  color: #1b3e90;
  font-weight: bold;
}

.btn-list a:hover {
  background: #1b3e90;
  border-color: #1b3e90;
}

.btn-list a:hover,
.btn-list a:hover * {
  color: #fff;
}

.btn-list.banks a {
  padding: 5px;
}

.btn-list.banks a:hover {
  background: #F6F4EC;
}

button, .button {
  display: inline-block;
  color: #000;
  background: #fff;
  border: 1px solid #8d8d8d;
  border-radius: 3px;
  padding: 3px 15px;
  font-size: 13px;
  line-height: 26px;
  transition: .5s;
}

.button:hover, button:hover {
  background: #1b3e90;
  color: #fff;
  border-color: #1b3e90;
}
